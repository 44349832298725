
import './App.css';
import './assets/sass/fonts.scss';

import Pc from './assets/components/Pc';
import Mobile from './assets/components/Mobile';

import './assets/sass/Mobile.scss';
function App() {
    const innerWidth = window.innerWidth;

  return (
    <>
    { innerWidth > 720 ? 
      <Pc/>
      :
      <Mobile/>
      }
    </>
  );
}

export default App;
