import '../../App.css';
import '../../assets/sass/fonts.scss';

import '../sass/Mobile.scss';

import Header from './MoHeader';
import Body from './MoBody';
import Footer from '../../assets/components/Footer';

const Mobile = () => {
    return(
    <>
        <Header/>
        <Body/>
        <Footer/>
      </>
    )
}

export default Mobile;