
import '../sass/Header.scss';

import logo from '../images/logo.png';
import { useEffect, useRef } from 'react';

const MoveHeader = () => {

    const headerSticky = useRef(null);
    const About = useRef(null);
    const OurService = useRef(null);
    const Contact = useRef(null);

    const scrollAbout = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const scrollService = () => {
        window.scrollTo({
            top: 2160,
            behavior: 'smooth'
        });
    };
    const scrollContact = () => {
        window.scrollTo({
            top: 4160,
            behavior: 'smooth'
        });
    };


    useEffect( () => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[]);

    const handleScroll = () => {
        const scrollTop = document.querySelector('html').scrollTop;
        if ( scrollTop > 150 ) {
            headerSticky.current.className = 'show';
        }else {
            headerSticky.current.className = 'hide';
        }

        if ( scrollTop < 2160 ) {
            About.current.className = 'active';
        }else {
            About.current.className = '';
        }

        if ( scrollTop >= 2160 && scrollTop < 4160 ) {
            OurService.current.className = 'active';
        }else {
            OurService.current.className = '';
        }

        if ( scrollTop >= 4160 ) {
            Contact.current.className = 'active';
        }else {
            Contact.current.className = '';
        }
    }
    

    return(
        <header className='hide' ref={headerSticky}>
            <div className='header-wrapper'>
                <a className='logo' href='/'>
                    <img src={logo} alt="로고"/>
                </a>
                <ul className='header-nav'>
                    <li ref={About} onClick={scrollAbout}>
                        <p>ABOUT</p>
                    </li>
                    <li ref={OurService} onClick={scrollService}>
                        <p>OUR SERVICE</p>
                    </li>
                    <li ref={Contact} onClick={scrollContact}>
                        <p>CONTACT</p>
                    </li>
                </ul>
            </div>
        </header>
    )
};

export default MoveHeader;