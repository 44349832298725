
import '../sass/Footer.scss';

const Footer = () => {
    return(
        <footer>
            <div className='footer-wrapper'>
                <p className="company">
                    TODES
                </p>
                <p className="copyright">
                    ⓒ TODES. All rights reserved.
                </p>
            </div>
        </footer>
    )
}

export default Footer;