
import '../sass/Header.scss';

import logo from '../images/logo.png';
import { useEffect } from 'react';
import { useRef } from 'react';

const MoHeader = () => {

    const About = useRef(null);
    const OurService = useRef(null);
    const Contact = useRef(null);

    const scrollAbout = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const scrollService = () => {
        window.scrollTo({
            top: 1344,
            behavior: 'smooth'
        });
    };
    const scrollContact = () => {
        window.scrollTo({
            top: 2885,
            behavior: 'smooth'
        });
    };

    useEffect( () => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[]);

    const handleScroll = () => {
        const scrollTop = document.querySelector('html').scrollTop;

        if ( scrollTop < 1344 ) {
            About.current.className = 'active';
        }else {
            About.current.className = '';
        }

        if ( scrollTop >= 1344 && scrollTop < 2885 ) {
            OurService.current.className = 'active';
        }else {
            OurService.current.className = '';
        }

        if ( scrollTop >= 2885 ) {
            Contact.current.className = 'active';
        }else {
            Contact.current.className = '';
        }
    }

    return(
        <header>
            <div className='header-wrapper'>
                <a className='logo' href='/'>
                    <img src={logo} alt="로고"/>
                </a>
                <ul className='header-nav'>
                    <li className='active' onClick={scrollAbout} ref={About}>
                        <p>ABOUT</p>
                    </li>
                    <li onClick={scrollService} ref={OurService}>
                        <p>OUR SERVICE</p>
                    </li>
                    <li onClick={scrollContact} ref={Contact}>
                        <p>CONTACT</p>
                    </li>
                </ul>
            </div>
        </header>
    )
};

export default MoHeader;