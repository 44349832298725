
import '../sass/Header.scss';

import logo from '../images/logo.png';

const PcHeader = () => {

    const scrollAbout = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const scrollService = () => {
        window.scrollTo({
            top: 2160,
            behavior: 'smooth'
        });
    };
    const scrollContact = () => {
        window.scrollTo({
            top: 4160,
            behavior: 'smooth'
        });
    };

    return(
        <header>
            <div className='header-wrapper'>
                <a className='logo' href='/'>
                    <img src={logo} alt="로고"/>
                </a>
                <ul className='header-nav'>
                    <li className='active' onClick={scrollAbout}>
                        <p>ABOUT</p>
                    </li>
                    <li onClick={scrollService}>
                        <p>OUR SERVICE</p>
                    </li>
                    <li onClick={scrollContact}>
                        <p>CONTACT</p>
                    </li>
                </ul>
            </div>
        </header>
    )
};

export default PcHeader;