
import '../sass/Body.scss';

import banner from '../images/section01.jpg';
import video01 from '../video/video01.mp4';
import video02 from '../video/video02.mp4';
import { useRef, useEffect } from 'react';

const PcBody = () => {

    const section03Title = useRef(null);
    const section05Title = useRef(null);
    const section05LeftTitle = useRef(null);
    const section05RightTitle = useRef(null);
    const section07Title = useRef(null);
    const section07SmallTitle01 = useRef(null);
    const section07SmallTitle02 = useRef(null);
    const section07SmallTitle03 = useRef(null);
    const section07SmallTitle04 = useRef(null);
    const section07SmallTitle05 = useRef(null);
    const section03Text = useRef(null);
    const section05Text = useRef(null);
    const section05LeftText = useRef(null);
    const section05RightText = useRef(null);
    const section07Text = useRef(null);

    

    useEffect( () => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },[]);

    const handleScroll = () => {
        const scrollTop = document.querySelector('html').scrollTop;
        if ( scrollTop > 900 ) {
            section03Title.current.className = 'title up';
        }else {
            section03Title.current.className = 'title';
        }

        if ( scrollTop > 1030 ) {
            section03Text.current.className = 'text-list up';
        }else {
            section03Text.current.className = 'text-list';
        }

        if ( scrollTop > 2330 ) {
            section05Title.current.className = 'title up';
        }else {
            section05Title.current.className = 'title';
        }

        if ( scrollTop > 2460 ) {
            section05Text.current.className = 'text-list up';
        }else {
            section05Text.current.className = 'text-list';
        }

        if ( scrollTop > 2900 ) {
            section05LeftTitle.current.className = 'title back';
            section05RightTitle.current.className = 'title back';
            section05LeftText.current.className = 'text back';
            section05RightText.current.className = 'text back';
        }else {
            section05LeftTitle.current.className = 'title';
            section05RightTitle.current.className = 'title';
            section05LeftText.current.className = 'text';
            section05RightText.current.className = 'text';
        }

        if ( scrollTop > 4312 ) {
            section07Title.current.className = 'title up';
        }else {
            section07Title.current.className = 'title';
        }
        if ( scrollTop > 4420 ) {
            section07Text.current.className = 'text-list up';
        }else {
            section07Text.current.className = 'text-list';
        }

        if ( scrollTop > 4812 ) {
            section07SmallTitle01.current.className = 'text up';
            section07SmallTitle02.current.className = 'text up';
            section07SmallTitle03.current.className = 'text up';
            section07SmallTitle04.current.className = 'text up';
            section07SmallTitle05.current.className = 'text up';
        }else {
            section07SmallTitle01.current.className = 'text';
            section07SmallTitle02.current.className = 'text';
            section07SmallTitle03.current.className = 'text';
            section07SmallTitle04.current.className = 'text';
            section07SmallTitle05.current.className = 'text';
        }
    }

    return(
        <div className="main-body">
            <section className="section01">
                <h4 className='title'>누구나 마법같은<br/>마케팅을 꿈꿉니다.</h4>
                <div className="text-list">
                    <p>많은 돈을 들이지 않더라도<br/>많은 시간과 노력을 들이지 않더라도</p>
                    <p>마케팅만 하면,<br/>어마어마한 성과가 나타날 것이라고<br/>바라면서 말입니다.</p>
                </div>
            </section>
            <section className='section02'>
                <div className='banner'>
                    <img src={banner} alt="배너"/>
                </div>
            </section>
            <section className='section03'>
                <h4 className='title' ref={section03Title}>하지만, 말하는 사람마다<br/>마케팅을 다르게 말합니다.</h4>
                <div className="text-list" ref={section03Text}>
                    <p>디지털 마케팅, SNS 마케팅,<br/>블로그 마케팅, 유튜브 마케팅 등등.</p>
                    <p>마케팅 방법론에 대해서만<br/>이야기만 할 뿐.</p>
                    <p>진짜 마케팅에 대해서는 아무도 이야기를 하지 않고 있습니다.</p>
                </div>
            </section>
            <section className='section04'>
                <div className='banner'>
                    <video autoPlay="autoplay" muted={true} loop={true}>
                        <source src={video01} type="video/mp4"/>
                    </video>
                </div>
            </section>
            <section className='section05'>
                <h4 className='title' ref={section05Title}>진짜 마케팅을<br/>시작하려고 합니다.</h4>
                <div className="text-list" ref={section05Text}>
                    <p>단순히 마케팅 기법으로써의 접근이 아닌<br/>장기적인 차원에서 큰 그림.</p>
                    <p>비전과 미션,<br/>그리고 전략방향(포지셔닝)을 먼저 잡고<br/>이에 맞춰 체계적으로 마케팅을 수행</p>
                    <p>이를 통해 소비자 속에서<br/>살아 움직이는 브랜드를 만듭니다.</p>
                </div>
                <ul className='tags'>
                    <li>
                        <h4 className='title' ref={section05LeftTitle}>
                            User<br/>eXperience<br/>center
                        </h4>
                        <p ref={section05LeftText}>
                            소비자가 경험하고 즐길 수 있는<br/>
                            브랜드를 만들기 위해 소비자를 연구하고<br/>
                            소비자 특성에 맞는 커뮤니케이션<br/>
                            방법론을 만들어갑니다.
                        </p>
                    </li>
                    <li>
                        <h4 className='title' ref={section05RightTitle}>
                            Brand<br/>eXperience<br/>lab
                        </h4>
                        <p className='text' ref={section05RightText}>
                            기존 조사결과에서 브랜드 인사이트만을<br/>
                            뽑는 컨설팅을 탈피하여 브랜드가<br/>
                            이를 소비하는 소비자 마음 속에서<br/>
                            살아 움직이는 방법을 고민해 나갑니다.
                        </p>
                    </li>
                </ul>
            </section>
            <section className='section06'>
                <div className='banner'>
                    <video autoPlay="autoplay" muted={true} loop={true}>
                        <source src={video02} type="video/mp4"/>
                    </video>
                </div>
            </section>
            <section className='section07'>
                <h4 className='title' ref={section07Title}>이렇게<br/>시작합니다.</h4>
                <div className="text-list" ref={section07Text}>
                    <p>그 동안의 비즈니스로 대하는<br/>전문가, 대행사를 벗어나.</p>
                    <p>우리의 결과물, 그 자체가 소통의 시작이고<br/>우리가 하는 일, 그 자체가 브랜드를<br/>만드는 것이라 믿으며...</p>
                </div>
                <ul className='contact'>
                    <li>
                        <p className='text' ref={section07SmallTitle01}>
                            CONTACT US
                        </p>
                        <p className='text' ref={section07SmallTitle02}>
                            서울특별시 서초구 방배로 140,<br/>4층 401호
                        </p>
                        <p className='text' ref={section07SmallTitle03}>
                            +82. 02.571.0960
                        </p>
                    </li>
                    <li>
                        <p className='text' ref={section07SmallTitle04}>
                            프로젝트 / 채용 문의
                        </p>
                        <p className='text' ref={section07SmallTitle05}>
                            contact@todes.co.kr
                        </p>
                    </li>
                </ul>
            </section>
        </div>
    )
}

export default PcBody;