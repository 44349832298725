import '../../App.css';
import '../../assets/sass/fonts.scss';

import Header from './PcHeader';
import Body from './PcBody';
import Footer from './Footer';
import MoveHeader from './MoveHeader';

const Pc = () => {
    return(
    <>
        <Header/>
        <MoveHeader/>
        <Body/>
        <Footer/>
      </>
    )
}

export default Pc;